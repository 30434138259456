import Awake from '../Albums/Awake.jpeg';
import Awake_Remixes from '../Albums/Awake_Remixes.jpeg';
import Dive from '../Albums/Dive.jpeg';
import Epoch from '../Albums/Epoch.jpeg';
import Fugue_State from '../Albums/Fugue_State.jpeg';
import Hill_Climber from '../Albums/Hill_Climber.jpeg';
import Innerspeaker from '../Albums/Innerspeaker.jpeg';
import Live_Versions from '../Albums/Live_Versions.jpeg';
import Lonerism from '../Albums/Lonerism.jpeg';
import Mr_Finish_Line from '../Albums/Mr_Finish_Line.jpeg';
import Past_Is_Prologue from '../Albums/Past_Is_Prologue.jpeg';
import Tame_Impala from '../Albums/Tame_Impala.jpeg';
import The_Beautiful_Game from '../Albums/The_Beautiful_Game.jpeg';
import The_Science_Of_Patterns from '../Albums/The_Science_Of_Patterns.jpeg';
import Thrill_Of_The_Arts from '../Albums/Thrill_Of_The_Arts.jpeg';
import uuidv4 from 'uuid';

export default [
  {
    alt: 'awake',
    image: Awake,
    flipped: false,
    matched: false,
    matchId: 1,
    uniqueId: uuidv4(),
  },
  {
    alt: 'awake_remixes',
    image: Awake_Remixes,
    flipped: false,
    matched: false,
    matchId: 2,
    uniqueId: uuidv4(),
  },
  {
    alt: 'dive',
    image: Dive,
    flipped: false,
    matched: false,
    matchId: 3,
    uniqueId: uuidv4(),
  },
  {
    alt: 'epoch',
    image: Epoch,
    flipped: false,
    matched: false,
    matchId: 4,
    uniqueId: uuidv4(),
  },
  {
    alt: 'fugue_state',
    image: Fugue_State,
    flipped: false,
    matched: false,
    matchId: 5,
    uniqueId: uuidv4(),
  },
  {
    alt: 'hill_climber',
    image: Hill_Climber,
    flipped: false,
    matched: false,
    matchId: 6,
    uniqueId: uuidv4(),
  },
  {
    alt: 'innerspeaker',
    image: Innerspeaker,
    flipped: false,
    matched: false,
    matchId: 7,
    uniqueId: uuidv4(),
  },
  {
    alt: 'live_versions',
    image: Live_Versions,
    flipped: false,
    matched: false,
    matchId: 8,
    uniqueId: uuidv4(),
  },
  {
    alt: 'lonerism',
    image: Lonerism,
    flipped: false,
    matched: false,
    matchId: 9,
    uniqueId: uuidv4(),
  },
  {
    alt: 'mr_finish_line',
    image: Mr_Finish_Line,
    flipped: false,
    matched: false,
    matchId: 10,
    uniqueId: uuidv4(),
  },
  {
    alt: 'past_is_prologue',
    image: Past_Is_Prologue,
    flipped: false,
    matched: false,
    matchId: 11,
    uniqueId: uuidv4(),
  },
  {
    alt: 'tame_impala',
    image: Tame_Impala,
    flipped: false,
    matched: false,
    matchId: 12,
    uniqueId: uuidv4(),
  },
  {
    alt: 'the_beautiful_game',
    image: The_Beautiful_Game,
    flipped: false,
    matched: false,
    matchId: 13,
    uniqueId: uuidv4(),
  },
  {
    alt: 'the_science_of_patterns',
    image: The_Science_Of_Patterns,
    flipped: false,
    matched: false,
    matchId: 14,
    uniqueId: uuidv4(),
  },
  {
    alt: 'thrill_of_the_arts',
    image: Thrill_Of_The_Arts,
    flipped: false,
    matched: false,
    matchId: 15,
    uniqueId: uuidv4(),
  },
  {
    alt: 'awake',
    image: Awake,
    flipped: false,
    matched: false,
    matchId: 1,
    uniqueId: uuidv4(),
  },
  {
    alt: 'awake_remixes',
    image: Awake_Remixes,
    flipped: false,
    matched: false,
    matchId: 2,
    uniqueId: uuidv4(),
  },
  {
    alt: 'dive',
    image: Dive,
    flipped: false,
    matched: false,
    matchId: 3,
    uniqueId: uuidv4(),
  },
  {
    alt: 'epoch',
    image: Epoch,
    flipped: false,
    matched: false,
    matchId: 4,
    uniqueId: uuidv4(),
  },
  {
    alt: 'fugue_state',
    image: Fugue_State,
    flipped: false,
    matched: false,
    matchId: 5,
    uniqueId: uuidv4(),
  },
  {
    alt: 'hill_climber',
    image: Hill_Climber,
    flipped: false,
    matched: false,
    matchId: 6,
    uniqueId: uuidv4(),
  },
  {
    alt: 'innerspeaker',
    image: Innerspeaker,
    flipped: false,
    matched: false,
    matchId: 7,
    uniqueId: uuidv4(),
  },
  {
    alt: 'live_versions',
    image: Live_Versions,
    flipped: false,
    matched: false,
    matchId: 8,
    uniqueId: uuidv4(),
  },
  {
    alt: 'lonerism',
    image: Lonerism,
    flipped: false,
    matched: false,
    matchId: 9,
    uniqueId: uuidv4(),
  },
  {
    alt: 'mr_finish_line',
    image: Mr_Finish_Line,
    flipped: false,
    matched: false,
    matchId: 10,
    uniqueId: uuidv4(),
  },
  {
    alt: 'past_is_prologue',
    image: Past_Is_Prologue,
    flipped: false,
    matched: false,
    matchId: 11,
    uniqueId: uuidv4(),
  },
  {
    alt: 'tame_impala',
    image: Tame_Impala,
    flipped: false,
    matched: false,
    matchId: 12,
    uniqueId: uuidv4(),
  },
  {
    alt: 'the_beautiful_game',
    image: The_Beautiful_Game,
    flipped: false,
    matched: false,
    matchId: 13,
    uniqueId: uuidv4(),
  },
  {
    alt: 'the_science_of_patterns',
    image: The_Science_Of_Patterns,
    flipped: false,
    matched: false,
    matchId: 14,
    uniqueId: uuidv4(),
  },
  {
    alt: 'thrill_of_the_arts',
    image: Thrill_Of_The_Arts,
    flipped: false,
    matched: false,
    matchId: 15,
    uniqueId: uuidv4(),
  },
];
